// Router
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";

// Store
import store from "@/store/index";

const routerRedirectToDashboard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  switch (store.getters.getUser.role.ability) {
    case "administrator":
      next({ name: "AdministrationAdministratorDashboardIndex" });
      break;
    case "owner":
      next({ name: "AdministrationOwnerDashboardIndex" });
      break;
    case "super-administrator":
      next({ name: "AdministrationSuperAdministratorDashboardIndex" });
      break;
    default:
      store.dispatch("setLogout").then((): void => {
        next({ name: "HomeIndex" });
      });
  }
};

const routerGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.meta.protectedRoute && store.getters.getAuthentication.token !== "") {
    next();
  } else if (to.meta.protectedRoute && store.getters.getAuthentication.token === "") {
    next({ name: "HomeIndex" });
  } else if (!to.meta.protectedRoute && store.getters.getAuthentication.token !== "") {
    routerRedirectToDashboard(to, from, next);
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "home-index" */ "../views/home/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/legal/privacy-policy",
    name: "LegalPrivacyPolicyIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "legal-privacy-policy-index" */ "../views/legal/privacy-policy/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/legal/terms-of-use",
    name: "LegalTermsOfUseIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "legal-terms-of-use-index" */ "../views/legal/terms-of-use/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/onboarding",
    name: "OnboardingIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "onboarding-index" */ "../views/onboarding/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/login",
    name: "AuthenticationLoginIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-login-index" */ "../views/authentication/login/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery",
    name: "AuthenticationPasswordRecoveryIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-index" */ "../views/authentication/password-recovery/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery/change-password/:token",
    name: "AuthenticationPasswordRecoveryChangePasswordIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-change-password-index" */ "../views/authentication/password-recovery/change-password/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/dashboard",
    name: "AdministrationAdministratorDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-dashboard-index" */ "../views/administration/administrator/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/partners",
    name: "AdministrationAdministratorPartnersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-partners-index" */ "../views/administration/administrator/partners/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/partners/:partner_id/view",
    name: "AdministrationAdministratorPartnersViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-partners-view-index" */ "../views/administration/administrator/partners/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/partners/:partner_id/deals",
    name: "AdministrationAdministratorPartnersDealsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-partners-deals-index" */ "../views/administration/administrator/partners/deals/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/partners/:partner_id/partner-conversations",
    name: "AdministrationAdministratorPartnersPartnerConversationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-partners-partner-conversations-index" */ "../views/administration/administrator/partners/partner-conversations/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/partners/:partner_id/actions",
    name: "AdministrationAdministratorPartnersActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-partners-actions-index" */ "../views/administration/administrator/partners/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/deals",
    name: "AdministrationAdministratorDealsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-deals-index" */ "../views/administration/administrator/deals/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/deals/:deal_id/view",
    name: "AdministrationAdministratorDealsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-deals-view-index" */ "../views/administration/administrator/deals/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/deals/:deal_id/deal-conversations",
    name: "AdministrationAdministratorDealsDealConversationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-deals-deal-conversations-index" */ "../views/administration/administrator/deals/deal-conversations/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/deals/:deal_id/actions",
    name: "AdministrationAdministratorDealsActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-deals-actions-index" */ "../views/administration/administrator/deals/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/sites",
    name: "AdministrationAdministratorSitesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-sites-index" */ "../views/administration/administrator/sites/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/sites/:site_id/view",
    name: "AdministrationAdministratorSitesViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-sites-view-index" */ "../views/administration/administrator/sites/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/sites/:site_id/site-logs",
    name: "AdministrationAdministratorSitesSiteLogsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-sites-site-logs-index" */ "../views/administration/administrator/sites/site-logs/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/sites/:site_id/site-conversations",
    name: "AdministrationAdministratorSitesSiteConversationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-sites-site-conversations-index" */ "../views/administration/administrator/sites/site-conversations/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/sites/:site_id/site-conversions",
    name: "AdministrationAdministratorSitesSiteConversionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-sites-site-conversions-index" */ "../views/administration/administrator/sites/site-conversions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/sites/:site_id/site-casinos",
    name: "AdministrationAdministratorSitesSiteCasinosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-sites-site-casinos-index" */ "../views/administration/administrator/sites/site-casinos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/sites/:site_id/actions",
    name: "AdministrationAdministratorSitesActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-sites-actions-index" */ "../views/administration/administrator/sites/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/onboardings",
    name: "AdministrationAdministratorOnboardingsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-onboardings-index" */ "../views/administration/administrator/onboardings/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/onboardings/:onboarding_id/view",
    name: "AdministrationAdministratorOnboardingsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-onboardings-view-index" */ "../views/administration/administrator/onboardings/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/onboardings/:onboarding_id/actions",
    name: "AdministrationAdministratorOnboardingsActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-onboardings-actions-index" */ "../views/administration/administrator/onboardings/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/offboardings",
    name: "AdministrationAdministratorOffboardingsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-offboardings-index" */ "../views/administration/administrator/offboardings/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/offboardings/:offboarding_id/view",
    name: "AdministrationAdministratorOffboardingsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-offboardings-view-index" */ "../views/administration/administrator/offboardings/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/offboardings/:offboarding_id/actions",
    name: "AdministrationAdministratorOffboardingsActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-offboardings-actions-index" */ "../views/administration/administrator/offboardings/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/notifications",
    name: "AdministrationAdministratorNotificationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-notifications-index" */ "../views/administration/administrator/notifications/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/casinos",
    name: "AdministrationAdministratorCasinosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-casinos-index" */ "../views/administration/administrator/casinos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/tokens",
    name: "AdministrationAdministratorTokensIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-tokens-index" */ "../views/administration/administrator/tokens/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/error/404",
    name: "AdministrationAdministratorError404Index",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-error-404-index" */ "../views/administration/administrator/error/404/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/dashboard",
    name: "AdministrationOwnerDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-dashboard-index" */ "../views/administration/owner/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/partners",
    name: "AdministrationOwnerPartnersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-partners-index" */ "../views/administration/owner/partners/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/partners/:partner_id/view",
    name: "AdministrationOwnerPartnersViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-partners-view-index" */ "../views/administration/owner/partners/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/partners/:partner_id/deals",
    name: "AdministrationOwnerPartnersDealsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-partners-deals-index" */ "../views/administration/owner/partners/deals/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/partners/:partner_id/partner-conversations",
    name: "AdministrationOwnerPartnersPartnerConversationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-partners-partner-conversations-index" */ "../views/administration/owner/partners/partner-conversations/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/partners/:partner_id/actions",
    name: "AdministrationOwnerPartnersActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-partners-actions-index" */ "../views/administration/owner/partners/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/deals",
    name: "AdministrationOwnerDealsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-deals-index" */ "../views/administration/owner/deals/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/deals/:deal_id/view",
    name: "AdministrationOwnerDealsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-deals-view-index" */ "../views/administration/owner/deals/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/deals/:deal_id/deal-conversations",
    name: "AdministrationOwnerDealsDealConversationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-deals-deal-conversations-index" */ "../views/administration/owner/deals/deal-conversations/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/deals/:deal_id/actions",
    name: "AdministrationOwnerDealsActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-deals-actions-index" */ "../views/administration/owner/deals/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/sites",
    name: "AdministrationOwnerSitesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-sites-index" */ "../views/administration/owner/sites/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/sites/:site_id/view",
    name: "AdministrationOwnerSitesViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-sites-view-index" */ "../views/administration/owner/sites/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/sites/:site_id/site-logs",
    name: "AdministrationOwnerSitesSiteLogsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-sites-site-logs-index" */ "../views/administration/owner/sites/site-logs/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/sites/:site_id/site-conversations",
    name: "AdministrationOwnerSitesSiteConversationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-sites-site-conversations-index" */ "../views/administration/owner/sites/site-conversations/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/sites/:site_id/site-conversions",
    name: "AdministrationOwnerSitesSiteConversionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-sites-site-conversions-index" */ "../views/administration/owner/sites/site-conversions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/sites/:site_id/site-casinos",
    name: "AdministrationOwnerSitesSiteCasinosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-sites-site-casinos-index" */ "../views/administration/owner/sites/site-casinos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/sites/:site_id/actions",
    name: "AdministrationOwnerSitesActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-sites-actions-index" */ "../views/administration/owner/sites/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/onboardings",
    name: "AdministrationOwnerOnboardingsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-onboardings-index" */ "../views/administration/owner/onboardings/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/onboardings/:onboarding_id/view",
    name: "AdministrationOwnerOnboardingsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-onboardings-view-index" */ "../views/administration/owner/onboardings/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/onboardings/:onboarding_id/actions",
    name: "AdministrationOwnerOnboardingsActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-onboardings-actions-index" */ "../views/administration/owner/onboardings/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/offboardings",
    name: "AdministrationOwnerOffboardingsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-offboardings-index" */ "../views/administration/owner/offboardings/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/offboardings/:offboarding_id/view",
    name: "AdministrationOwnerOffboardingsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-offboardings-view-index" */ "../views/administration/owner/offboardings/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/offboardings/:offboarding_id/actions",
    name: "AdministrationOwnerOffboardingsActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-offboardings-actions-index" */ "../views/administration/owner/offboardings/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/users",
    name: "AdministrationOwnerUsersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-users-index" */ "../views/administration/owner/users/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/webhooks",
    name: "AdministrationOwnerWebhooksIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-webhooks-index" */ "../views/administration/owner/webhooks/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/notifications",
    name: "AdministrationOwnerNotificationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-notifications-index" */ "../views/administration/owner/notifications/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/casinos",
    name: "AdministrationOwnerCasinosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-casinos-index" */ "../views/administration/owner/casinos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/scrapers",
    name: "AdministrationOwnerScrapersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-scrapers-index" */ "../views/administration/owner/scrapers/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/tokens",
    name: "AdministrationOwnerTokensIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-tokens-index" */ "../views/administration/owner/tokens/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/owner/error/404",
    name: "AdministrationOwnerError404Index",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-owner-error-404-index" */ "../views/administration/owner/error/404/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/dashboard",
    name: "AdministrationSuperAdministratorDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-dashboard-index" */ "../views/administration/super-administrator/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/partners",
    name: "AdministrationSuperAdministratorPartnersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-partners-index" */ "../views/administration/super-administrator/partners/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/partners/:partner_id/view",
    name: "AdministrationSuperAdministratorPartnersViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-partners-view-index" */ "../views/administration/super-administrator/partners/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/partners/:partner_id/deals",
    name: "AdministrationSuperAdministratorPartnersDealsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-partners-deals-index" */ "../views/administration/super-administrator/partners/deals/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/partners/:partner_id/partner-conversations",
    name: "AdministrationSuperAdministratorPartnersPartnerConversationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-partners-partner-conversations-index" */ "../views/administration/super-administrator/partners/partner-conversations/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/partners/:partner_id/actions",
    name: "AdministrationSuperAdministratorPartnersActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-partners-actions-index" */ "../views/administration/super-administrator/partners/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/deals",
    name: "AdministrationSuperAdministratorDealsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-deals-index" */ "../views/administration/super-administrator/deals/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/deals/:deal_id/view",
    name: "AdministrationSuperAdministratorDealsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-deals-view-index" */ "../views/administration/super-administrator/deals/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/deals/:deal_id/deal-conversations",
    name: "AdministrationSuperAdministratorDealsDealConversationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-deals-deal-conversations-index" */ "../views/administration/super-administrator/deals/deal-conversations/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/deals/:deal_id/actions",
    name: "AdministrationSuperAdministratorDealsActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-deals-actions-index" */ "../views/administration/super-administrator/deals/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/sites",
    name: "AdministrationSuperAdministratorSitesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-sites-index" */ "../views/administration/super-administrator/sites/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/sites/:site_id/view",
    name: "AdministrationSuperAdministratorSitesViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-sites-view-index" */ "../views/administration/super-administrator/sites/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/sites/:site_id/site-logs",
    name: "AdministrationSuperAdministratorSitesSiteLogsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-sites-site-logs-index" */ "../views/administration/super-administrator/sites/site-logs/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/sites/:site_id/site-conversations",
    name: "AdministrationSuperAdministratorSitesSiteConversationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-sites-site-conversations-index" */ "../views/administration/super-administrator/sites/site-conversations/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/sites/:site_id/site-conversions",
    name: "AdministrationSuperAdministratorSitesSiteConversionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-sites-site-conversions-index" */ "../views/administration/super-administrator/sites/site-conversions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/sites/:site_id/site-casinos",
    name: "AdministrationSuperAdministratorSitesSiteCasinosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-sites-site-casinos-index" */ "../views/administration/super-administrator/sites/site-casinos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/sites/:site_id/actions",
    name: "AdministrationSuperAdministratorSitesActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-sites-actions-index" */ "../views/administration/super-administrator/sites/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/onboardings",
    name: "AdministrationSuperAdministratorOnboardingsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-onboardings-index" */ "../views/administration/super-administrator/onboardings/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/onboardings/:onboarding_id/view",
    name: "AdministrationSuperAdministratorOnboardingsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-onboardings-view-index" */ "../views/administration/super-administrator/onboardings/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/onboardings/:onboarding_id/actions",
    name: "AdministrationSuperAdministratorOnboardingsActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-onboardings-actions-index" */ "../views/administration/super-administrator/onboardings/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/offboardings",
    name: "AdministrationSuperAdministratorOffboardingsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-offboardings-index" */ "../views/administration/super-administrator/offboardings/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/offboardings/:offboarding_id/view",
    name: "AdministrationSuperAdministratorOffboardingsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-offboardings-view-index" */ "../views/administration/super-administrator/offboardings/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/offboardings/:offboarding_id/actions",
    name: "AdministrationSuperAdministratorOffboardingsActionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-offboardings-actions-index" */ "../views/administration/super-administrator/offboardings/actions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/users",
    name: "AdministrationSuperAdministratorUsersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-users-index" */ "../views/administration/super-administrator/users/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/webhooks",
    name: "AdministrationSuperAdministratorWebhooksIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-webhooks-index" */ "../views/administration/super-administrator/webhooks/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/notifications",
    name: "AdministrationSuperAdministratorNotificationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-notifications-index" */ "../views/administration/super-administrator/notifications/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/casinos",
    name: "AdministrationSuperAdministratorCasinosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-casinos-index" */ "../views/administration/super-administrator/casinos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/scrapers",
    name: "AdministrationSuperAdministratorScrapersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-scrapers-index" */ "../views/administration/super-administrator/scrapers/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/tokens",
    name: "AdministrationSuperAdministratorTokensIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-tokens-index" */ "../views/administration/super-administrator/tokens/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/error/404",
    name: "AdministrationSuperAdministratorError404Index",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-error-404-index" */ "../views/administration/super-administrator/error/404/index.vue"),
    beforeEnter: routerGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isRoleIncluded = to.path.includes("role");

  if (isRoleIncluded) {
    const role = store.getters.getUser.role.ability;
    const path = to.path.replace("role", role);

    next({ path: path });
  } else {
    next();
  }
});

export default router;
