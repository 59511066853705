<template>
  <div class="navigation">
    <div class="navigation__menu">
      <el-menu @select="setRoute" v-if="isUserRoleAbilityAdministrator">
        <el-menu-item index="AdministrationAdministratorDashboardIndex">
          <template #title>
            <span class="icon">
              <House />
            </span>
            <span class="label"> Dashboard </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">Content</span>
        </span>
        <el-menu-item index="AdministrationAdministratorPartnersIndex">
          <template #title>
            <span class="icon">
              <Coin />
            </span>
            <span class="label"> Partners </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationAdministratorDealsIndex">
          <template #title>
            <span class="icon">
              <Connection />
            </span>
            <span class="label"> Deals </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationAdministratorSitesIndex">
          <template #title>
            <span class="icon">
              <Monitor />
            </span>
            <span class="label"> Sites </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationAdministratorCasinosIndex">
          <template #title>
            <span class="icon">
              <Money />
            </span>
            <span class="label"> Casinos </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">Onboarding</span>
        </span>
        <el-menu-item index="AdministrationAdministratorOnboardingsIndex">
          <template #title>
            <span class="icon">
              <MessageBox />
            </span>
            <span class="label"> Onboardings </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationAdministratorOffboardingsIndex">
          <template #title>
            <span class="icon">
              <MessageBox />
            </span>
            <span class="label"> Offboardings </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">Analytics</span>
        </span>
        <el-menu-item index="AdministrationAdministratorTokensIndex">
          <template #title>
            <span class="icon">
              <Key />
            </span>
            <span class="label"> Tokens </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">Other</span>
        </span>
        <el-menu-item index="AdministrationAdministratorNotificationsIndex">
          <template #title>
            <span class="icon">
              <Notification />
            </span>
            <span class="label"> Notifications </span>
          </template>
        </el-menu-item>
      </el-menu>
      <el-menu @select="setRoute" v-if="isUserRoleAbilityOwner">
        <el-menu-item index="AdministrationOwnerDashboardIndex">
          <template #title>
            <span class="icon">
              <House />
            </span>
            <span class="label"> Dashboard </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">Content</span>
        </span>
        <el-menu-item index="AdministrationOwnerPartnersIndex">
          <template #title>
            <span class="icon">
              <Coin />
            </span>
            <span class="label"> Partners </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationOwnerDealsIndex">
          <template #title>
            <span class="icon">
              <Connection />
            </span>
            <span class="label"> Deals </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationOwnerSitesIndex">
          <template #title>
            <span class="icon">
              <Monitor />
            </span>
            <span class="label"> Sites </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationOwnerCasinosIndex">
          <template #title>
            <span class="icon">
              <Money />
            </span>
            <span class="label"> Casinos </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">Onboarding</span>
        </span>
        <el-menu-item index="AdministrationOwnerOnboardingsIndex">
          <template #title>
            <span class="icon">
              <MessageBox />
            </span>
            <span class="label"> Onboardings </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationOwnerOffboardingsIndex">
          <template #title>
            <span class="icon">
              <MessageBox />
            </span>
            <span class="label"> Offboardings </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">Analytics</span>
        </span>
        <el-menu-item index="AdministrationOwnerTokensIndex">
          <template #title>
            <span class="icon">
              <Key />
            </span>
            <span class="label"> Tokens </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">Other</span>
        </span>
        <el-sub-menu index="AdministrationOwnerSettingsIndex">
          <template #title>
            <span class="icon">
              <Setting />
            </span>
            <span class="label">Settings</span>
          </template>
          <el-menu-item index="AdministrationOwnerUsersIndex">
            <template #title>
              <span class="label"> Users </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="AdministrationOwnerNotificationsIndex">
          <template #title>
            <span class="icon">
              <Notification />
            </span>
            <span class="label"> Notifications </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationOwnerScrapersIndex">
          <template #title>
            <span class="icon">
              <DataBoard />
            </span>
            <span class="label"> Scrapers </span>
          </template>
        </el-menu-item>
      </el-menu>
      <el-menu @select="setRoute" v-if="isUserRoleAbilitySuperAdministrator">
        <el-menu-item index="AdministrationSuperAdministratorDashboardIndex">
          <template #title>
            <span class="icon">
              <House />
            </span>
            <span class="label"> Dashboard </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">Content</span>
        </span>
        <el-menu-item index="AdministrationSuperAdministratorPartnersIndex">
          <template #title>
            <span class="icon">
              <Coin />
            </span>
            <span class="label"> Partners </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationSuperAdministratorDealsIndex">
          <template #title>
            <span class="icon">
              <Connection />
            </span>
            <span class="label"> Deals </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationSuperAdministratorSitesIndex">
          <template #title>
            <span class="icon">
              <Monitor />
            </span>
            <span class="label"> Sites </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationSuperAdministratorCasinosIndex">
          <template #title>
            <span class="icon">
              <Money />
            </span>
            <span class="label"> Casinos </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">Onboarding</span>
        </span>
        <el-menu-item index="AdministrationSuperAdministratorOnboardingsIndex">
          <template #title>
            <span class="icon">
              <MessageBox />
            </span>
            <span class="label"> Onboardings </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationSuperAdministratorOffboardingsIndex">
          <template #title>
            <span class="icon">
              <MessageBox />
            </span>
            <span class="label"> Offboardings </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">Analytics</span>
        </span>
        <el-menu-item index="AdministrationSuperAdministratorTokensIndex">
          <template #title>
            <span class="icon">
              <Key />
            </span>
            <span class="label"> Tokens </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">Other</span>
        </span>
        <el-sub-menu index="AdministrationSuperAdministratorSettingsIndex">
          <template #title>
            <span class="icon">
              <Setting />
            </span>
            <span class="label">Settings</span>
          </template>
          <el-menu-item index="AdministrationSuperAdministratorUsersIndex">
            <template #title>
              <span class="label"> Users </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorWebhooksIndex">
            <template #title>
              <span class="label"> Webhooks </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="AdministrationSuperAdministratorNotificationsIndex">
          <template #title>
            <span class="icon">
              <Notification />
            </span>
            <span class="label"> Notifications </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationSuperAdministratorScrapersIndex">
          <template #title>
            <span class="icon">
              <DataBoard />
            </span>
            <span class="label"> Scrapers </span>
          </template>
        </el-menu-item>
      </el-menu>
      <el-menu @select="setDialog">
        <el-menu-item index="ProfileIndex">
          <template #title>
            <span class="icon">
              <Bell />
            </span>
            <span class="label"> Send Slack Notification </span>
          </template>
        </el-menu-item>
      </el-menu>
    </div>
    <el-dialog title="Send Slack Notification" v-model="isDialogVisible" class="el-dialog--is-scrollable">
      <el-form :model="formData" :rules="formDataRules" ref="formComponent" @submit.prevent="validateForm">
        <el-form-item label="Title" prop="title">
          <el-input v-model="formData.title" />
        </el-form-item>
        <el-form-item label="Message" prop="message">
          <el-input v-model="formData.message" type="textarea" />
        </el-form-item>
        <el-form-item label="Webhooks" prop="webhooks">
          <el-select v-model="formData.webhooks" placeholder="Choose" filterable clearable multiple>
            <el-option :label="webhook.name" :value="webhook.id" v-for="webhook in webhooks" :key="webhook.id" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="el-button--is-primary el-button--is-block" native-type="submit" :loading="isLoading">Send</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script lang="ts">
// Vue
import { Options, Vue } from "vue-class-component";

// Store
import store from "@/store";

// Models
import { AppUser } from "@/models/app/user";
import { AppWebhook } from "@/models/app/webhook";
import { Request } from "@/models/request";
import { Response } from "@/models/response";
import { SlackNotification } from "@/models/slack-notification";

// Components
import { ElMenu, ElMenuItemGroup, ElMenuItem, ElSubMenu, ElDialog, ElForm, ElFormItem, ElInput, ElSelect, ElOption, ElButton } from "element-plus";
import { Connection, Coin, DataBoard, House, Key, MessageBox, Notification, Monitor, Money, ShoppingBag, Setting, Bell } from "@element-plus/icons-vue";

// Services
import { getRequest, postRequest } from "@/services/api/request";

@Options({
  components: {
    ElButton,
    ElMenu,
    ElMenuItemGroup,
    ElMenuItem,
    ElSubMenu,
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    Connection,
    Coin,
    DataBoard,
    House,
    Key,
    MessageBox,
    Notification,
    Monitor,
    Money,
    ShoppingBag,
    Setting,
    Bell,
  },
})
export default class Navigation extends Vue {
  isLoading = false;
  isDialogVisible = false;

  requestWebhooks: Request = new Request();

  webhooks: AppWebhook[] = [];

  formData: SlackNotification = new SlackNotification();
  formDataRules = {
    title: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    message: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    webhooks: [
      {
        required: true,
        trigger: "blur",
      },
    ],
  };

  $refs!: {
    formComponent: HTMLFormElement;
  };

  get user(): AppUser {
    return store.getters.getUser;
  }

  get isUserRoleAbilityAdministrator(): boolean {
    return store.getters.getUser.role.ability === "administrator";
  }

  get isUserRoleAbilityOwner(): boolean {
    return store.getters.getUser.role.ability === "owner";
  }

  get isUserRoleAbilitySuperAdministrator(): boolean {
    return store.getters.getUser.role.ability === "super-administrator";
  }

  resetFormData(): void {
    this.formData = new SlackNotification();
  }

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: `/administration/${this.user.role.ability}/notification/send/slack-notification`,
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: Response) => {
      if (r.status === "success") {
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  async getWebhooks(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/administration/${store.getters.getUser.role.ability}/webhook`,
      formData: this.requestWebhooks,
      isProtected: true,
    }).then((r: Response) => {
      if (r.data) {
        this.webhooks = r.data.webhooks;
      }
    });
    this.isLoading = false;
  }

  async getView(): Promise<void> {
    await this.getWebhooks();
  }

  async setRequests(): Promise<void> {
    this.requestWebhooks = {
      searchField: null,
      searchQuery: null,
      with: null,
      where: null,
      whereIn: null,
      orWhere: null,
      orderBy: "name",
      orderType: "asc",
      filterBy: null,
      filterValue: null,
      limit: null,
      paginate: null,
      page: null,
    };
  }

  setRoute(routeName: string): void {
    this.$router.push({
      name: routeName,
    });
  }

  setDialog(): void {
    this.isDialogVisible = true;
  }

  async created(): Promise<void> {
    await this.setRequests();
    await this.getView();
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/core/_variables.scss";
@import "~@/assets/scss/mixins/_breakpoints.scss";

.navigation {
  width: 256px;
  min-width: 256px;
  min-height: calc(100vh - 134px);
  padding: 16px;
  border-right: 1px solid $grey-60;

  &__menu {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    :deep(.el-menu) {
      background-color: transparent;
      border-right: 0;
    }

    .el-sub-menu {
      border-radius: 4px;

      .icon {
        width: 16px;
        margin-right: 8px;
        position: relative;
        top: -2px;
      }

      .label {
        font-size: 14px;
        font-weight: 400;
        font-family: $secondary-font;
      }

      * {
        vertical-align: middle;
      }

      .el-menu {
        .el-menu-item {
          margin-left: 24px;
        }
      }
    }

    .el-menu-item {
      height: 48px;
      padding-right: 12px !important;
      padding-left: 12px !important;
      border-radius: 4px;

      .icon {
        width: 16px;
        margin-right: 8px;
        position: relative;
        top: -2px;
      }

      .label {
        font-size: 14px;
        font-weight: 400;
        font-family: $secondary-font;
      }

      * {
        vertical-align: middle;
      }

      &.is-active {
        color: $primary;
      }
    }

    .separator {
      display: block;
      margin-top: 24px;
      margin-bottom: 12px;
      padding: 0 4px;

      &__title {
        font-size: 12px;
        font-weight: 700;
        font-family: $secondary-font;
      }
    }
  }
}
</style>
