<template>
  <div class="header">
    <el-container>
      <div class="header__content">
        <div class="header__content-logo">Seven Media CRM</div>
        <div class="header__content-action">
          <div class="action">
            <a href="#" class="action__user"> Hi, {{ user.name }} </a>
            <div class="action__menu" v-click-outside="setIsActionMenuBoxNotificationVisible">
              <a href="#" class="action__menu-icon" @click.prevent="isActionMenuBoxNotificationVisible = true">
                <Notification />
              </a>
              <div class="action__menu-box" :class="{ 'action__menu-box--is-visible': isActionMenuBoxNotificationVisible }">
                <div class="action__menu-box-title">Notifications</div>
                <ul>
                  <li v-for="notification in notifications" :key="notification.id">
                    <a :href="getNotificationUrl(notification)">
                      <div class="notification">
                        <div class="notification__icon" v-if="getNotificationType(notification) === 'Partner'">
                          <Coin />
                        </div>
                        <div class="notification__icon" v-if="getNotificationType(notification) === 'Deal'">
                          <Connection />
                        </div>
                        <div class="notification__icon" v-if="getNotificationType(notification) === 'Site'">
                          <View />
                        </div>
                        <div class="notification__icon" v-if="getNotificationType(notification) === 'Offboarding'">
                          <MessageBox />
                        </div>
                        <div class="notification__icon" v-if="getNotificationType(notification) === 'Onboarding'">
                          <MessageBox />
                        </div>
                        <div class="notification__content">
                          <div class="notification__content-message">{{ notification.message }}</div>
                          <div class="notification__content-created_at">
                            {{ getDateTimeFormat(notification.created_at) }}
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="is-view-all" v-if="isNotificationsSet">
                    <router-link :to="{ name: 'AdministrationAdministratorNotificationsIndex' }" v-if="isUserRoleAbilityAdministrator">View all</router-link>
                    <router-link :to="{ name: 'AdministrationOwnerNotificationsIndex' }" v-if="isUserRoleAbilityOwner">View all</router-link>
                    <router-link :to="{ name: 'AdministrationSuperAdministratorNotificationsIndex' }" v-if="isUserRoleAbilitySuperAdministrator">View all</router-link>
                  </li>
                </ul>
                <div class="action__menu-box-message" v-if="isNotificationsSet === false">Currently, there are no notifications...</div>
              </div>
            </div>
            <div class="action__menu" v-click-outside="setIsActionMenuBoxUserVisible">
              <a href="#" class="action__menu-icon" @click.prevent="isActionMenuBoxUserVisible = true">
                <User />
              </a>
              <div class="action__menu-box" :class="{ 'action__menu-box--is-visible': isActionMenuBoxUserVisible }">
                <div class="action__menu-box-title">Menu</div>
                <ul>
                  <li>
                    <a href="#" @click.prevent>Settings</a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="setLogout">Logout</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script lang="ts">
// Vue
import { Options, Vue } from "vue-class-component";

// Vendors
import Pusher from "pusher-js";
import vClickOutside from "click-outside-vue3";

// Store
import store from "@/store";

// Models
import { AppNotification } from "@/models/app/notification";
import { AppUser } from "@/models/app/user";
import { Response } from "@/models/response";
import { Request } from "@/models/request";

// Components
import { ElContainer } from "element-plus";
import { Connection, Coin, MessageBox, Notification, User, View } from "@element-plus/icons-vue";

// Services
import { getRequest } from "@/services/api/request";
import { postRequest } from "@/services/api/request";
import { getDateTimeFormat } from "@/services/app/date";

@Options({
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    ElContainer,
    Connection,
    Coin,
    MessageBox,
    Notification,
    User,
    View,
  },
})
export default class Header extends Vue {
  isActionMenuBoxUserVisible = false;
  isActionMenuBoxNotificationVisible = false;

  requestNotifications: Request = new Request();

  notifications: AppNotification[] = [];

  get user(): AppUser {
    return store.getters.getUser;
  }

  get isUserRoleAbilityAdministrator(): boolean {
    return store.getters.getUser.role.ability === "administrator";
  }

  get isUserRoleAbilityOwner(): boolean {
    return store.getters.getUser.role.ability === "owner";
  }

  get isUserRoleAbilitySuperAdministrator(): boolean {
    return store.getters.getUser.role.ability === "super-administrator";
  }

  get isNotificationsSet(): boolean {
    return this.notifications.length > 0;
  }

  async getNotifications(): Promise<void> {
    await getRequest({
      uri: `/administration/${this.user.role.ability}/notification`,
      formData: this.requestNotifications,
      isProtected: true,
    }).then((r: Response) => {
      if (r.data) {
        this.notifications = r.data.notifications;
      }
    });
  }

  async getView(): Promise<void> {
    await this.getNotifications();
  }

  getDateTimeFormat(date: string): string {
    return getDateTimeFormat(date);
  }

  getNotificationType(notification: AppNotification): string {
    return notification.notificationable_type.substring(11);
  }

  getNotificationUrl(notification: AppNotification): string {
    if (notification.action === "created") {
      return `/administration/${this.user.role.ability}/${notification.notificationable_type.substring(11).toLowerCase()}s/${notification.notificationable_id}/view`;
    }

    if (notification.action === "updated") {
      return `/administration/${this.user.role.ability}/${notification.notificationable_type.substring(11).toLowerCase()}s/${notification.notificationable_id}/actions`;
    }

    return "#";
  }

  setIsActionMenuBoxNotificationVisible(): void {
    this.isActionMenuBoxNotificationVisible = false;
  }

  setIsActionMenuBoxUserVisible(): void {
    this.isActionMenuBoxUserVisible = false;
  }

  async setPusherUpdateNotificationChannel(): Promise<void> {
    const pusher = new Pusher(`${process.env.VUE_APP_PUSHER_KEY}`, {
      cluster: `${process.env.VUE_APP_PUSHER_CLUSTER}`,
    });

    const channel = pusher.subscribe("notification");

    channel.bind("update", async (): Promise<void> => {
      await this.getView();
    });
  }

  async setRequests(): Promise<void> {
    this.requestNotifications = {
      searchField: null,
      searchQuery: null,
      with: JSON.stringify(["notificationable"]),
      where: null,
      whereIn: null,
      orWhere: null,
      orderBy: "created_at",
      orderType: "desc",
      filterBy: null,
      filterValue: null,
      limit: "8",
      paginate: null,
      page: null,
    };
  }

  async setLogout(): Promise<void> {
    await postRequest({
      uri: "/authentication/logout",
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then(() => {
      store.dispatch("setLogout");

      this.$router.push({
        name: "HomeIndex",
      });
    });
  }

  async created(): Promise<void> {
    await this.setPusherUpdateNotificationChannel();
    await this.setRequests();
    await this.getView();
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/core/_variables.scss";
@import "~@/assets/scss/mixins/_breakpoints.scss";

.header {
  background-color: $white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  z-index: 1020;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;

    &-logo {
      font-size: 16px;
      font-family: $primary-font;
      font-weight: 700;
      color: $black;
    }

    &-action {
      display: flex;
      align-items: center;

      .action {
        display: flex;
        align-items: center;

        &__user {
          font-size: 14px;
          font-weight: 600;
          color: $black;
        }

        &__menu {
          position: relative;

          &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            background-color: $black;
            margin-left: 12px;

            svg {
              width: 16px;
              color: $white;
            }
          }

          &-box {
            position: absolute;
            top: 64px;
            right: 0;
            padding: 24px;
            min-width: 280px;
            max-width: 480px;
            width: auto;
            background-color: $white;
            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            display: none;
            z-index: 1060;

            &::before {
              content: "";
              position: absolute;
              top: -5px;
              right: 10px;
              display: block;
              width: 12px;
              height: 12px;
              background: $white;
              box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
              transform: rotate(45deg);
            }

            &--is-visible {
              display: block;
            }

            &-title {
              font-size: 16px;
              font-weight: 700;
              color: $black;
            }

            &-message {
              font-size: 12px;
            }

            ul {
              list-style: none;
              margin: 24px 0 0;
              padding: 0;

              li {
                padding-top: 8px;
                padding-bottom: 8px;
                border-bottom: 1px solid $grey-70;

                &:first-child {
                  padding-top: 0;
                }

                &:last-child {
                  padding-bottom: 0;
                  border-bottom: 0;
                }

                a {
                  font-size: 14px;
                  color: $black;

                  &:hover,
                  &:active,
                  &:focus,
                  &:focus:active {
                    color: $primary;
                  }

                  .notification {
                    display: flex;
                    align-items: flex-start;

                    &__icon {
                      width: 28px;
                      height: 28px;
                      min-width: 28px;
                      min-height: 28px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background-color: $grey-70;
                      border-radius: 50%;

                      svg {
                        width: 12px;
                      }
                    }

                    &__content {
                      margin-left: 12px;

                      &-message {
                        font-size: 12px;
                        font-weight: 700;
                      }

                      &-created_at {
                        font-size: 12px;
                        font-family: $secondary-font;
                        margin-top: 4px;
                      }
                    }
                  }
                }

                &.is-view-all {
                  a {
                    display: block;
                    padding: 8px 0;
                    font-size: 14px;
                    font-weight: 700;
                    color: $primary;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
