export interface SlackNotification {
  title: string;
  message: string;
  webhooks: string[];
}

export class SlackNotification implements SlackNotification {
  public title: string;
  public message: string;
  public webhooks: string[];

  constructor() {
    this.title = "";
    this.message = "";
    this.webhooks = [];
  }
}
