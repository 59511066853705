// Vendors
import moment, { Moment } from "moment";

const getDateFormat = (data: string): string => {
  if (data) {
    return moment(data).format("DD.MM.YYYY.");
  }

  return "n/a";
};

const getDateFormatUTC = (data: string): string => {
  if (data) {
    return moment(moment.utc(data).toDate()).local().format("DD.MM.YYYY.");
  }

  return "n/a";
};

const getDateFormatSchedule = (data: string): string => {
  if (data) {
    return moment(data).format("YYYY-MM-DD HH:mm");
  }

  return "n/a";
};

const getDateTimeFormat = (data: string): string => {
  if (data) {
    return moment(data).format("DD.MM.YYYY. - HH:mm");
  }

  return "n/a";
};

const getDateTimeFormatUTC = (data: string): string => {
  if (data) {
    return moment(moment.utc(data).toDate()).local().format("DD.MM.YYYY. - HH:mm");
  }

  return "n/a";
};

const getTimeFormat = (data: string): string => {
  if (data) {
    return moment(data).format("HH:mm");
  }

  return "n/a";
};

const getToday = (): Moment => {
  return moment();
};

const getTodayTimestamp = (): string => {
  return moment().format("YYYY-MM-DD HH:mm:ss");
};

const getTodayStatus = (data: string): boolean => {
  if (data) {
    return moment(data).isAfter(new Date(), "day");
  }

  return false;
};

const getTodayOffset = (): Moment => {
  return moment().add(14, "days");
};

const getTodayOffsetForStatistics = (): Moment => {
  return moment().subtract(14, "days");
};

const getYear = (): string => {
  return moment().format("YYYY");
};

const getMonthFirstDay = (): Moment => {
  return moment().startOf("month");
};

const getMonthLastDay = (): Moment => {
  return moment().endOf("month");
};

const getCurrentMonthFirstDay = (data: string): string => {
  if (data) {
    return moment(data).startOf("month").format("DD-MM-YYYY");
  }

  return "n/a";
};

const getCurrentMonthLastDay = (data: string): string => {
  if (data) {
    return moment(data).endOf("month").format("DD-MM-YYYY");
  }

  return "n/a";
};

export { getDateFormat, getDateFormatUTC, getDateFormatSchedule, getDateTimeFormat, getDateTimeFormatUTC, getTimeFormat, getToday, getTodayTimestamp, getTodayStatus, getTodayOffset, getTodayOffsetForStatistics, getYear, getMonthFirstDay, getMonthLastDay, getCurrentMonthFirstDay, getCurrentMonthLastDay };
