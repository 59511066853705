import { AppUser } from "@/models/app/user";

export interface AppUserInformation {
  id: number | null;
  user_id: number | null;
  meta: {
    name: string;
    address: string;
    city: string;
    province: string;
    country: string;
    postal_code: string;
    phone: string;
    email: string;
  };
  created_at: string;
  updated_at: string;
  user: AppUser;
}

export class AppUserInformation implements AppUserInformation {
  public id: number | null;
  public user_id: number | null;
  public meta: {
    name: string;
    address: string;
    city: string;
    province: string;
    country: string;
    postal_code: string;
    phone: string;
    email: string;
  };
  public created_at: string;
  public updated_at: string;
  public user: AppUser;

  public constructor() {
    this.id = null;
    this.user_id = null;
    this.meta = {
      name: "",
      address: "",
      city: "",
      province: "",
      country: "",
      postal_code: "",
      phone: "",
      email: "",
    };
    this.created_at = "";
    this.updated_at = "";
    this.user = new AppUser();
  }
}
