export interface Response {
  data: any;
  message: string;
  status: string;
  statusCode: number;
}

export class Response implements Response {
  public data: any;
  public message: string;
  public status: string;
  public statusCode: number;
  constructor() {
    this.data = null;
    this.message = "";
    this.status = "";
    this.statusCode = 200;
  }
}
