import { AppRolePermission } from "@/models/app/role-permission";

export interface AppRole {
  id: number | null;
  name: string;
  ability: string;
  created_at: string;
  updated_at: string;
  role_permission: AppRolePermission[];
}

export class AppRole implements AppRole {
  public id: number | null;
  public name: string;
  public ability: string;
  public created_at: string;
  public updated_at: string;
  public role_permission: AppRolePermission[];

  public constructor() {
    this.id = null;
    this.name = "";
    this.ability = "";
    this.created_at = "";
    this.updated_at = "";
    this.role_permission = [];
  }
}
